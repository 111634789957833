/* eslint-disable no-unused-vars */

<template>
  <div class="login">
    <GoogleTranslate v-if="showLanguageDialog" />
    <p class="HardReloadButton" @click="doHardReload">Hard Reload</p>
    <div class="bg"></div>
    <div class="login-div" scrolling="no">
      <div class="logos">
        <span id="img-01" v-if="loginClient !== 'gtr' && loginClient !== 'gtrt'">
          <img src="@/assets/AIkenist.png" />
        </span>
        <span v-if="loginClient === 'telerapp'" id="img-02">
          <img src="@/assets/telerapp_logo.png" />
        </span>
        <span v-if="loginClient === 'gtr' || loginClient === 'gtrt'" id="img-03">
          <img src="@/assets/gtr_logo_no_bg.png" />
        </span>
      </div>

      <div class="rad"><i class="el-icon-user-solid"> </i> Login</div>

      <div v-if="mfaRequired == true">
        <span style="font-size: 14px">
          We have shared a code to your registered email.
          <br />
          Please enter the code below to login.
        </span>
        <div class="text-field-position">
          <input
            type="text"
            name="mfa_code"
            placeholder="Authentication Code"
            class="login-input"
            v-model="mfaDetails.mfa_code"
          />
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <button class="submitMfaCode" @click="validateMfaLogin">
              Sign In
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="text-field-position">
          <input
            type="text"
            name="email"
            placeholder="Email"
            class="login-input"
            v-model="currentEmail"
          />
          <span v-if="cleartext" class="removeClick" @click="clearemail"
            >&times;</span
          >
        </div>
        <div class="text-field-position">
          <input
            id="myInput"
            type="password"
            class="login-input"
            placeholder="Password"
            v-model="currentPassword"
            show-password
          />
          <span v-if="clearPassword" class="removeClick" @click="togglePassword"
            ><i class="el-icon-view"></i
          ></span>
        </div>

        <div>
          <el-button type="primary" @click="submitForm" round
            >Sign In</el-button
          >
        </div>
      </div>

      <br />
      <div id="outer" v-if="mfaRequired == false">
        <div class="inner">
          <el-button type="info" round>Cloud First<br />PACS</el-button>
        </div>
        <div class="inner">
          <el-button type="info" round>AI Enabled<br />Workflow</el-button>
        </div>
        <div class="inner">
          <el-button type="info" round>Advanced<br />Visualization</el-button>
        </div>
      </div>
      <el-tooltip
        class="item"
        effect="dark"
        content="Change Language"
        placement="top-start"
      >
        <img
          class="languageButton"
          src="@/assets/language.png"
          height="20px"
          @click="openLanguageDialog()"
        />
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import {aikenSuperAdminLogin } from "@/services/login/postLogin";
import { Mixins } from "@/mixins/mixins.code.js";
import radConfig from "@/config/config";
import GoogleTranslate from "./../GoogleTranslate.vue";
import { EventBus } from "@/event-bus.js";
export default {
  components: {
    GoogleTranslate,
  },
  data() {
    return {
      isGdcrib: false,
      loginDetails: "",
      styleChanged: false,
      showLanguageDialog: false,
      loginClient: "",
      msg: [],
      cleartext: false,
      clearPassword: false,
      currentEmail: "",
      currentPassword: "",
      mfaRequired: false,

      posts: {
        password: "",
        email: "",
      },
      mfaDetails: {
        mfa_code: "",
        user_type: "",
        user_email: "",
      },
    };
  },

  watch: {
    currentEmail(value) {
      if (value.length > 0) {
        this.cleartext = true;
      } else {
        this.cleartext = false;
      }
    },
    currentPassword(value) {
      if (value.length > 0) {
        this.clearPassword = true;
      } else {
        this.clearPassword = false;
      }
    },
  },

  beforeDestroy() {
    clearInterval(this.sliderInterval);
  },
  methods: {
    checkQuotaExceededError(e) {
      if (
        e.name === "QUOTA_EXCEEDED_ERR" ||
        e.name === "NS_ERROR_DOM_QUOTA_REACHED" ||
        e.name === "QuotaExceededError" ||
        e.code === 22 ||
        e.code === 1014
      ) {
        this.doHardReload();
      }
    },
    observeStyleChange() {
      const targetNode = document.body;
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "style"
          ) {
            const topValue = targetNode.style.top;
            try {
              if (topValue === "0px") {
                this.styleChanged = false;
                console.log("UI unchanged");
                localStorage.setItem("tooltipTransform", "false");
              } else {
                this.styleChanged = true;
                console.log("UI Shifted");
                localStorage.setItem("tooltipTransform", "true");
              }
            } catch (e) {
              this.checkQuotaExceededError(e);
            }
          }
        });
      });

      observer.observe(targetNode, {
        attributes: true,
        attributeFilter: ["style"],
      });
    },
    openLanguageDialog() {
      this.showLanguageDialog = !this.showLanguageDialog;
      //console.log(process.env.NODE_ENV); // Should print 'development' or 'production'
    },

    doHardReload() {
      localStorage.clear();
      sessionStorage.clear();
      caches.keys().then(function (names) {
        for (let name of names) {
          caches.delete(name);
        }
      });
      location.reload();
    },
    togglePassword() {
      var x = document.getElementById("myInput");
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },

    clearemail() {
      // console.log(this.currentEmail);
      this.currentEmail = "";
    },
    validateEmail(value) {
      if (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        )
      ) {
        this.msg["email"] = "";
      } else {
        this.msg["email"] = "Invalid Email Address";
      }
    },

    submitForm() {
      try {
        this.posts.email = this.currentEmail;
        this.posts.password = this.currentPassword;
        localStorage.setItem("currentEmail", this.currentEmail);
    aikenSuperAdminLogin(this.posts).then((res) => {
          this.loginDetails = res;
          let loginStatus = res.data.status;
          let loginAccessToken = res.data.token;

          if (res.data.is_blocked == true) {
            this.$notify.error({
              title: "User is Blocked.",
              message: "Please contact your admin for further details.",
              duration: "2500",
            });
            return;
          }

          //console.log(res.data);
          localStorage.setItem("UserName", res.data.name);
          let name = this.currentEmail.substring(
            0,
            this.currentEmail.lastIndexOf("@")
          );
          localStorage.setItem("LoginName", name);

          // **********************  MFA Login ************************ //

          if (res.data.is_mfa_required == true) {
            this.mfaRequired = true;
            this.mfa_code = "";
            console.log("MFA Required");
          } else {
             if (
              loginStatus == true &&
              res.data.user_type == "aiken_super_admin"
            ) {
              this.OpenLoader();
              localStorage.setItem("token", loginAccessToken);
              localStorage.setItem("loginAs", "Aiken Admin");
              this.$router.push({
                name: "Aiken Admin",
              });
              this.CloseLoader();
            } 
             else {
              this.$notify.error({
                title: "Please enter valid credentials.",
                message: "",
                duration: "2500",
              });
            }
          }
        });
      } catch (e) {
        this.checkQuotaExceededError(e);
      }
    },

    open() {
      this.$router.push({
        name: "ForgotPassword",
      });
    },
  },
  mixins: [Mixins],
};
</script>

<style>
.el-carousel {
  position: relative;
  margin-left: 10px;
}

button.el-carousel__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 24px;
}

button.el-carousel__arrow.el-carousel__arrow--left {
  left: 0;
  margin-left: 10px;
}

button.el-carousel__arrow.el-carousel__arrow--right {
  right: 0;
  margin-right: 10px;
}
.carousel-container {
  flex: 1;
  max-width: 66.6%;
  background-color: #f5f5f5;
}

.carousel-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.carousel-image {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.center-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 20px; */
  text-align: center;
}

.center-logo img {
  width: 100px;
  height: auto;
}

.login {
  position: relative;
}

.login-input {
  display: inline-block;
  height: 40px;
  width: 90%;
  font-size: 15px;
  color: #666666;
  text-indent: 8px;
  border: 1px solid #2d2d2d;
  margin: 15px !important;
  border: 0px solid #2d2d2d;
  border-bottom: 1px solid #3d3131;
}

.text-field-position {
  position: relative;
}

.text-field-position span {
  position: absolute;
  right: 2em;
  top: 0.5em;
  bottom: 0;
  line-height: 40px;
}

.languageButton:hover {
  cursor: pointer;
  scale: 1.05;
}
.languageButton {
  position: fixed;
  bottom: 10px;
  right: 5px;
  z-index: 1;
  cursor: pointer;
}

.goog-te-gadget {
  font-size: 10px !important;
}

.bg {
  background-image: url("../assets/bg.jpeg");
  height: 100vh;
  width: 70vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-div {
  position: absolute;
  padding: 10px;
  top: 0%;
  right: 0%;
  font-size: 25px;
  background-color: #fafafa;
  font-family: "Roboto";
  height: 100vh;
  width: 33vw;
  display: inline-block;
}

.UserLoginLink {
  position: fixed;
  margin: 20px;
  cursor: pointer;
  text-decoration: underline;
  z-index: 99;
}

.HardReloadButton {
  position: fixed;
  cursor: pointer;
  text-decoration: underline;
  z-index: 99;
  bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
  color: darkcyan;
}

.logos {
  display: flex;
  flex-direction: row;
  height: 20%;
  align-items: center;
  justify-content: space-between;
}

i.el-icon-view {
  font-size: 14px !important;
  /* margin: 0px !important; */
  padding: 0px !important;
  color: black !important;
}

#img-01 {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  width: 40%;
  height: 50%;
}

#img-01::before {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  width: 40%;
  height: 50%;
}

#img-03 {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  height: 45%;
}

#img-03::before {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  height: 45%;
}

#img-02 {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  width: 10%;
  height: auto;
  justify-content: flex-end;
}

#img-02::before {
  margin-top: 30px;
  display: flex;
  flex-direction: row-reverse;
  width: 10%;
  height: auto;
  justify-content: flex-end;
}

.rad {
  color: black !important;
  font-family: "Roboto";
  font-weight: 700;
  margin-top: 12%;
  margin-bottom: 10%;
  padding: 1%;
}

.el-icon-user-solid {
  color: black !important;
}

.el-icon-user-solid:before {
  color: black !important;
}

.inner {
  margin: 1% !important;
  display: inline-block;
  justify-content: center;
}

/* .el-notification {
  background-color: rgba(218, 122, 122, 0.9) !important;
  color: black !important;
} */

.inner_login-div {
  margin-top: 30px;
  margin-bottom: 0px;
  width: 80%;
  margin-left: 10% !important;
  padding: 2px;
}

.submitMfaCode {
  color: #fff !important;
  background-color: #409eff !important;
  /* border-color: #409eff !important; */
  border: none !important;
  font-size: 16px !important;
  border-radius: 20px !important;
  padding: 5px 10px !important;
  transition: 1.1s !important;
  cursor: pointer;
}

.el-button.el-button--primary {
  color: #fff !important;
  background-color: #409eff !important;
  border-color: #409eff !important;
  margin: 3px !important;
  margin-bottom: 8% !important;
  transition: 1.1s !important;
  font-weight: 900 !important;
  margin-left: 40% !important;
  font-size: 16px !important;
  border-radius: 20px !important;
  padding: 12px 23px !important;
}

.el-button.el-button--info {
  color: #fff !important;
  background-color: #909399 !important;
  margin: 5px !important;
  transition: 1.1s !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  margin-left: 27% !important;
  margin-top: 20% !important;
  padding: 5px !important;
  height: 4.5vh !important;
  width: 8vw !important;
  text-align: center !important;
  border-radius: 30px !important;
}

.el-button--text {
  color: #0080ff !important;
  margin-top: 1% !important;
}

@media only screen and (max-width: 1500px) {
  .el-button.el-button--info {
    margin: 5px !important;
    transition: 1.1s !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    margin-left: 20% !important;
    padding: 8px !important;
    height: 5vh !important;
    width: 9vw !important;
    text-align: center !important;
    border-radius: 30px !important;
  }

  .logos {
    display: flex;
    flex-direction: row;
    height: 20%;
    align-items: center;
    justify-content: space-between;
  }

  #img-01::before {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 40%;
    height: 45%;
  }

  #img-03::before {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    height: 40%;
  }

  #img-02::before {
    margin-top: 30px;
    display: flex;
    flex-direction: row-reverse;
    width: 10%;
    height: 20%;
    justify-content: flex-end;
  }

  #img-01 {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 40%;
    height: 45%;
  }

  #img-03 {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    height: 40%;
  }

  #img-02 {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 10%;
    height: 20%;
    justify-content: flex-end;
  }
}

@media only screen and (max-height: 800px) and (min-width: 1300px) {
  .login-div {
    position: absolute;
    padding: 10px;
    top: 0%;
    right: 0%;
    font-size: 25px;
    background-color: #fafafa;
    font-family: "Roboto";
    height: 100%;
    width: 33vw;
    display: inline-block;
  }

  #img-01::before {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
  }

  #img-01 {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
  }

  #img-03::before {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
  }

  #img-03 {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
  }

  #img-02::before {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 10%;
    height: 20%;
    justify-content: flex-end;
  }

  #img-02 {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 10%;
    height: 20%;
    justify-content: flex-end;
  }

  .rad {
    font-family: "Roboto";
    font-weight: 700;
    margin-top: 12%;
    margin-bottom: 10%;
    padding: 1%;
  }

  .el-radio {
    margin-left: 30;
    display: inline !important;
    cursor: pointer !important;
    margin: 5% !important;
    margin-left: 10% !important;
    font-family: "Roboto" !important;
    font-weight: 900 !important;
    font-size: larger !important;
    padding: 5% !important;
    margin-right: 30px !important;
    justify-content: center !important;
  }

  .login-input {
    display: inline-block;
    height: 30px;
    width: 80%;
    font-size: 15px;
    color: #666666;
    margin: 15px !important;
    text-indent: 8px;
    border: 0px solid #2d2d2d;
    border-bottom: 1px solid #3d3131;
  }

  .inner_login-div {
    margin-top: 30px;
    margin-bottom: 0px;
    width: 80%;
    margin-left: 10% !important;
    padding: 2px;
  }

  .el-button.el-button--primary {
    color: #fff !important;
    background-color: #409eff !important;
    border-color: #409eff !important;
    margin: 3px !important;
    margin-bottom: 3% !important;
    font-weight: 600 !important;
    margin-left: 40% !important;
    font-size: 16px !important;
    border-radius: 20px !important;
    padding: 12px 23px !important;
  }

  .el-button.el-button--info {
    margin: 5px !important;
    transition: 1.1s !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    margin-left: 20% !important;
    padding: 8px !important;
    height: 5.5vh !important;
    width: 9vw !important;
    text-align: center !important;
    border-radius: 30px !important;
    justify-content: center;
  }
}

@media only screen and (max-height: 700px) and (min-width: 1300px) {
  .login-div {
    position: absolute;
    padding: 10px;
    top: 0%;
    right: 0%;
    font-size: 25px;
    background-color: #fafafa;
    font-family: "Roboto";
    height: 100%;
    width: 33vw;
    display: inline-block;
  }

  .rad {
    font-family: "Roboto";
    font-weight: 700;
    margin-top: 10%;
    margin-bottom: 8%;
    padding: 1%;
  }

  .el-radio {
    margin-left: 30;
    display: inline !important;
    cursor: pointer !important;
    margin: 4% !important;
    margin-left: 10% !important;
    font-family: "Roboto" !important;
    font-weight: 900 !important;
    font-size: larger !important;
    padding: 5% !important;
    margin-right: 30px !important;
    justify-content: center !important;
  }

  .inner_login-div {
    margin-top: 30px;
    margin-bottom: 0px;
    width: 80%;
    margin-left: 10% !important;
    padding: 2px;
  }

  /* 
  .el-input__inner {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    border-radius: 0px !important;
    background-color: #fafafa !important;
    border: 1px solid #3a3a3b !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    color: #080808 !important;
    font-size: large !important;
  } */

  .el-button.el-button--primary {
    color: #fff !important;
    background-color: #409eff !important;
    border-color: #409eff !important;
    margin: 3px !important;
    margin-bottom: 3% !important;
    font-weight: 600 !important;
    margin-left: 40% !important;
    font-size: 16px !important;
    border-radius: 20px !important;
    padding: 12px 23px !important;
  }

  .el-button.el-button--info {
    margin: 5px !important;
    transition: 1.1s !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    margin-left: 20% !important;
    padding: 2px !important;
    height: 5vh !important;
    width: 9vw !important;
    text-align: center !important;
    border-radius: 30px !important;
  }
}

@media only screen and (max-height: 800px) {
  .logos {
    display: flex;
    flex-direction: row;
    height: 20%;
    align-items: center;
    justify-content: space-between;
  }

  #img-01::before {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    height: 50%;
  }

  #img-03::before {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    height: 50%;
  }

  #img-02::before {
    margin-top: 30px;
    display: flex;
    flex-direction: row-reverse;
    height: 20%;
  }

  #img-01 {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    height: 50%;
  }

  #img-03 {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    height: 50%;
  }

  #img-02 {
    margin-top: 30px;
    display: flex;
    flex-direction: row-reverse;
    height: 20%;
  }
}

@media only screen and (max-width: 1300px) {
  .el-button.el-button--info {
    margin: 5px !important;
    transition: 1.1s !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    margin-left: 20% !important;
    padding: 8px !important;
    height: 5vh !important;
    width: 9vw !important;
    text-align: center !important;
    border-radius: 30px !important;
  }

  .logos {
    display: flex;
    flex-direction: row;
    height: 20%;
    align-items: center;
    justify-content: space-between;
  }

  .text-field-position span {
    position: absolute;
    right: 1.5em;
    top: 0.5em;
    bottom: 0;
    line-height: 40px;
  }

  #img-01::before {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 40%;
    height: 40%;
  }

  #img-03::before {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 40%;
    height: 40%;
  }

  #img-02::before {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 10%;
    height: 20%;
    justify-content: flex-end;
  }

  #img-01 {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 40%;
    height: 40%;
  }

  #img-03 {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 40%;
    height: 40%;
  }

  #img-02 {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 10%;
    height: 20%;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 1050px) {
  .bg {
    background-image: url("../assets/bg.jpeg");
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .login-div {
    position: absolute;
    padding: 4px;
    margin-top: 35%;
    margin-right: 3vw;
    right: 5%;
    font-size: 20px;
    background-color: rgba(250, 250, 250, 0.9);
    font-family: "Roboto";
    height: inherit;
    width: 85vw;
    display: inline-block;
    border-radius: 8px;
  }

  .text-field-position span {
    position: absolute;
    right: 4em;
    top: 0.5em;
    bottom: 0;
    line-height: 40px;
  }

  .logos {
    display: flex;
    flex-direction: row;
    height: 15vh;
    align-items: center;
    justify-content: flex-start;
  }

  #img-01::before {
    display: flex;
    flex-direction: row;
    height: 60%;
  }

  #img-02::before {
    display: flex;
    flex-direction: row-reverse;
    height: 40%;
  }

  #img-01 {
    display: flex;
    flex-direction: row;
    height: 60%;
  }

  #img-02 {
    display: flex;
    flex-direction: row-reverse;
    height: 40%;
  }

  .rad {
    font-weight: 600;
    font-size: 17px;
    margin-top: 2px;
    margin-bottom: 5px;
    padding: 1%;
  }

  .el-radio {
    display: inline !important;
    cursor: pointer !important;
    margin: 7% !important;
    font-family: "Roboto" !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    padding: 3% !important;
    margin-right: 15px !important;
    justify-content: center !important;
  }

  /* 
  .el-input__icon {
    width: 44px !important;
    text-align: justify !important;
    transition: all 0.3s !important;
    line-height: 65px !important;
    margin-right: 2px !important;
  } */

  .el-button.el-button--primary {
    margin: 0px !important;
    margin-bottom: 0px !important;
    transition: 1.1s !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    margin-left: 37% !important;
    padding: 11px !important;
  }

  .el-button.el-button--info {
    margin-top: 0px !important;
    transition: 1.1s !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    margin-left: 10% !important;
    padding: 5px !important;
    height: 6vh !important;
    width: 23vw !important;
    text-align: center !important;
    border-radius: 30px !important;
  }

  .el-button--text {
    color: #0080ff !important;
    margin-top: 2% !important;
  }
}

@media only screen and (max-width: 900px) {
  .bg {
    background-image: url("../assets/bg.jpeg");
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .login-div {
    position: absolute;
    padding: 4px;
    margin-top: 25%;
    margin-right: 3vw;
    right: 5%;
    font-size: 20px;
    background-color: rgba(250, 250, 250, 0.9);
    font-family: "Roboto";
    height: inherit;
    width: 85vw;
    display: inline-block;
    border-radius: 8px;
  }

  .logos {
    display: flex;
    flex-direction: row;
    height: 15vh;
    align-items: center;
    justify-content: flex-start;
  }

  #img-01::before {
    display: flex;
    flex-direction: row;
    height: 60%;
  }

  #img-02::before {
    display: flex;
    flex-direction: row-reverse;
    height: 40%;
  }

  #img-01 {
    display: flex;
    flex-direction: row;
    height: 60%;
  }

  #img-02 {
    display: flex;
    flex-direction: row-reverse;
    height: 40%;
  }

  .rad {
    font-weight: 600;
    font-size: 17px;
    margin-top: 2px;
    margin-bottom: 5px;
    padding: 1%;
  }

  .el-radio {
    display: inline !important;
    cursor: pointer !important;
    margin: 7% !important;
    font-family: "Roboto" !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    padding: 3% !important;
    margin-right: 15px !important;
    justify-content: center !important;
  }

  /* .el-input__inner {
    margin-left: 10% !important;
    margin-top: 4% !important;
    margin-bottom: 1px !important;
    border-radius: 0px !important;
    background-color: rgba(250, 250, 250, 0.5) !important;
    border: 1px solid #3a3a3b !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    color: #080808 !important;
    font-size: 13px !important;
    width: 80% !important;
  }

  .el-input__icon {
    width: 44px !important;
    text-align: justify !important;
    transition: all 0.3s !important;
    line-height: 65px !important;
    margin-right: 2px !important;
  } */

  .el-button.el-button--primary {
    margin: 0px !important;
    margin-bottom: 0px !important;
    transition: 1.1s !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    margin-left: 37% !important;
    padding: 11px !important;
  }

  .el-button.el-button--info {
    margin-top: 0px !important;
    transition: 1.1s !important;
    font-weight: 100 !important;
    font-size: 18px !important;
    margin-left: 10% !important;
    padding: 5px !important;
    height: 6vh !important;
    width: 23vw !important;
    text-align: center !important;
    border-radius: 30px !important;
  }

  .el-button--text {
    color: #0080ff !important;
    margin-top: 2% !important;
  }
}

@media only screen and (max-width: 700px) {
  .bg {
    background-image: url("../assets/bg.jpeg");
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .login-div {
    position: absolute;
    padding: 4px;
    margin-top: 35%;
    margin-right: 3vw;
    right: 5%;
    font-size: 20px;
    background-color: rgba(250, 250, 250, 0.9);
    font-family: "Roboto";
    height: inherit;
    width: 85vw;
    display: inline-block;
    border-radius: 8px;
  }

  .text-field-position span {
    position: absolute;
    right: 5.5em;
    top: 0.5em;
    bottom: 0;
    line-height: 40px;
  }

  .login-input {
    display: inline-block;
    height: 30px;
    width: 80%;
    font-size: 15px;
    color: #666666;
    margin: 15px !important;
    text-indent: 8px;
    border: 0px solid #2d2d2d;
    border-bottom: 1px solid #3d3131;
  }

  .logos {
    display: flex;
    flex-direction: row;
    height: 15vh;
    align-items: center;
    justify-content: flex-start;
  }

  #img-01::before {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    height: 40%;
  }

  #img-02::before {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
    height: 30%;
  }

  #img-01 {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    height: 40%;
  }

  #img-02 {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
    height: 30%;
  }

  .rad {
    font-weight: 600;
    font-size: 17px;
    margin-top: 2px;
    margin-bottom: 5px;
    padding: 1%;
  }

  .el-radio {
    display: inline !important;
    cursor: pointer !important;
    margin: 7% !important;
    font-family: "Roboto" !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    padding: 3% !important;
    margin-right: 15px !important;
    justify-content: center !important;
  }

  /* .el-input__inner {
    margin-left: 10% !important;
    margin-top: 4% !important;
    margin-bottom: 1px !important;
    border-radius: 0px !important;
    background-color: rgba(250, 250, 250, 0.5) !important;
    border: 1px solid #3a3a3b !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    color: #080808 !important;
    font-size: 13px !important;
    width: 80% !important;
  }

  .el-input__icon {
    width: 44px !important;
    text-align: justify !important;
    transition: all 0.3s !important;
    line-height: 65px !important;
    margin-right: 2px !important;
  } */

  .el-button.el-button--primary {
    margin: 0px !important;
    margin-bottom: 0px !important;
    transition: 1.1s !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    margin-left: 37% !important;
    padding: 11px !important;
  }

  .el-button.el-button--info {
    margin-top: 0px !important;
    transition: 1.1s !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    margin-left: 10% !important;
    padding: 5px !important;
    height: 5vh !important;
    width: 23vw !important;
    text-align: center !important;
    border-radius: 30px !important;
  }

  .el-button--text {
    color: #0080ff !important;
    margin-top: 2% !important;
  }
}

@media only screen and (max-width: 600px) {
  .text-field-position span {
    position: absolute;
    right: 5em;
    top: 0.5em;
    bottom: 0;
    line-height: 40px;
  }
}

@media only screen and (max-width: 500px) {
  .text-field-position span {
    position: absolute;
    right: 4em;
    top: 0.5em;
    bottom: 0;
    line-height: 40px;
  }
}

@media only screen and (max-width: 400px) {
  .bg {
    background-image: url("../assets/bg.jpeg");
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .text-field-position span {
    position: absolute;
    right: 3em;
    top: 0.5em;
    bottom: 0;
    line-height: 40px;
  }

  .login-div {
    position: absolute;
    padding: 4px;
    margin-top: 35%;
    margin-right: 3vw;
    right: 5%;
    font-size: 20px;
    background-color: rgba(250, 250, 250, 0.9);
    font-family: "Roboto";
    height: inherit;
    width: 85vw;
    display: inline-block;
    border-radius: 8px;
  }

  .login-input {
    display: inline-block;
    height: 30px;
    width: 80%;
    font-size: 15px;
    color: #666666;
    margin: 15px !important;
    text-indent: 8px;
    border: 0px solid #2d2d2d;
    border-bottom: 1px solid #3d3131;
  }

  .logos {
    display: flex;
    flex-direction: row;
    height: 15vh;
    align-items: center;
    justify-content: flex-start;
  }

  #img-01::before {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    height: 40%;
  }

  #img-02::before {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
    height: 25%;
  }

  #img-01 {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    height: 40%;
  }

  #img-02 {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
    height: 25%;
  }

  .rad {
    font-weight: 600;
    font-size: 17px;
    margin-top: 2px;
    margin-bottom: 5px;
    padding: 1%;
  }

  .el-radio {
    display: inline !important;
    cursor: pointer !important;
    margin: 7% !important;
    font-family: "Roboto" !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    padding: 3% !important;
    margin-right: 15px !important;
    justify-content: center !important;
  }

  /* .el-input__inner {
    margin-left: 5% !important;
    margin-top: 4% !important;
    margin-bottom: 1px !important;
    border-radius: 0px !important;
    background-color: rgba(250, 250, 250, 0.5) !important;
    border: 1px solid #3a3a3b !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    color: #080808 !important;
    font-size: 11px !important;
    width: 90% !important;
  } */

  .el-button.el-button--primary {
    margin: 0px !important;
    margin-bottom: 0px !important;
    transition: 1.1s !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    margin-left: 37% !important;
    padding: 11px !important;
  }

  .el-button.el-button--info {
    margin-top: 0px !important;
    transition: 1.1s !important;
    font-weight: 550 !important;
    font-size: 11px !important;
    margin-left: 8% !important;
    padding: 5px !important;
    height: 6vh !important;
    width: 24vw !important;
    text-align: center !important;
    border-radius: 30px !important;
  }

  .el-button--text {
    color: #0080ff !important;
    margin-top: 2% !important;
  }
}

@media only screen and (max-width: 300px) {
  .bg {
    background-image: url("../assets/bg.jpeg");
    height: 100vh;
    width: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .text-field-position span {
    position: absolute;
    right: 2em;
    top: 0.5em;
    bottom: 0;
    line-height: 40px;
  }

  .login-div {
    position: absolute;
    padding: 4px;
    margin-top: 35%;
    margin-right: 3vw;
    right: 5%;
    font-size: 20px;
    background-color: rgba(250, 250, 250, 0.9);
    font-family: "Roboto";
    height: inherit;
    width: 85vw;
    display: inline-block;
    border-radius: 8px;
  }

  .logos {
    display: flex;
    flex-direction: row;
    height: 15vh;
    align-items: center;
    justify-content: flex-start;
  }

  .login-input {
    display: inline-block;
    height: 30px;
    width: 80%;
    font-size: 15px;
    color: #666666;
    margin: 15px !important;
    text-indent: 8px;
    border: 0px solid #2d2d2d;
    border-bottom: 1px solid #3d3131;
  }

  #img-01::before {
    display: flex;
    flex-direction: row;
    height: 40%;
  }

  #img-02::before {
    display: flex;
    flex-direction: row-reverse;
    height: 20%;
  }

  #img-01 {
    display: flex;
    flex-direction: row;
    height: 40%;
  }

  #img-02 {
    display: flex;
    flex-direction: row-reverse;
    height: 20%;
  }

  .rad {
    font-weight: 600;
    font-size: 17px;
    margin-top: 2px;
    margin-bottom: 5px;
    padding: 1%;
  }

  .el-radio {
    display: inline !important;
    cursor: pointer !important;
    margin: 4% !important;
    font-family: "Roboto" !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    padding: 3% !important;
    margin-right: 15px !important;
    justify-content: center !important;
  }

  /* .el-input__inner {
    margin-left: 5% !important;
    margin-top: 4% !important;
    margin-bottom: 1px !important;
    border-radius: 0px !important;
    background-color: rgba(250, 250, 250, 0.5) !important;
    border: 1px solid #3a3a3b !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    color: #080808 !important;
    font-size: 10px !important;
    width: 90% !important;
  } */

  /* .el-input__icon {
    width: 44px !important;
    text-align: justify !important;
    transition: all 0.3s !important;
    line-height: 65px !important;
    margin-right: 2px !important;
  } */

  .el-button.el-button--primary {
    margin: 0px !important;
    margin-bottom: 0px !important;
    transition: 1.1s !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    margin-left: 37% !important;
    padding: 11px !important;
  }

  .el-button.el-button--info {
    margin-top: 0px !important;
    transition: 1.1s !important;
    font-weight: 400 !important;
    font-size: 10px !important;
    margin-left: 5% !important;
    padding: 5px !important;
    height: 5vh !important;
    width: 23.5vw !important;
    text-align: center !important;
    border-radius: 30px !important;
  }

  .el-button--text {
    color: #0080ff !important;
    margin-top: 2% !important;
  }
}
</style>
