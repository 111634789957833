/* eslint-disable no-unused-vars */

<template>
  <div class="login">
    <div class="carousel-container" v-if="isGdcrib">
      <el-carousel
        :interval="3000"
        arrow="always"
        height="100vh"
        indicator-position="none"
      >
        <el-carousel-item v-for="(item, index) in carouselItems" :key="index">
          <div class="carousel-content">
            <img :src="item.image" alt="Loading image" class="carousel-image" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <GoogleTranslate v-if="showLanguageDialog" />
    <p class="HardReloadButton" @click="doHardReload">Hard Reload</p>
    <p
      class="HardReloadButton2"
      v-if="isLocalHost"
      @click="redirectToAikenSuperAdmin"
    >
      Aiken Super Admin Login
    </p>

    <!-- mediqas background -->
    <div style="display: flex; justify-content: flex-start; height: 100vh;" v-if="isMediqas">
      <img class="medi_bg" src="@/assets/medi_bg_full.jpg" alt="mediqas background" />
    </div>  

    <!-- normal background -->
    <div class="bg" v-else-if="!isGdcrib"></div>

    <div class="login-div" scrolling="no">
      <div class="logos">
        <span
          id="img-01"
          v-if="loginClient !== 'gtr' && loginClient !== 'gtrt' && loginClient !== 'mediqas'"
        >
          <img src="@/assets/AIkenist.png" />
        </span>
        <span v-if="loginClient === 'telerapp'" id="img-02">
          <img src="@/assets/telerapp_logo.png" />
        </span>
        <span v-if="loginClient === 'mediqas'" id="img-03">
          <img src="@/assets/mediqas_logo.png" />
        </span>
        <span
          v-if="loginClient === 'gtr' || loginClient === 'gtrt'"
          id="img-03"
        >
          <img src="@/assets/gtr_logo_no_bg.png" />
        </span>
        <span v-if="loginClient === 'chartwell'" id="img-03">
          <img src="@/assets/chartwell_logo.jpeg" />
        </span>
      </div>

      <div class="rad"><i class="el-icon-user-solid"> </i> Login</div>

      <div v-if="mfaRequired == true">
        <span style="font-size: 14px">
          We have shared a code to your registered email.
          <br />
          Please enter the code below to login.
        </span>
        <div class="text-field-position">
          <input
            type="text"
            name="mfa_code"
            placeholder="Authentication Code"
            class="login-input"
            v-model="mfaDetails.mfa_code"
          />
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <button class="submitMfaCode" @click="validateMfaLogin">
              Sign In
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="text-field-position">
          <input
            type="text"
            name="email"
            placeholder="Email"
            class="login-input"
            v-model="currentEmail"
          />
          <span v-if="cleartext" class="removeClick" @click="clearemail"
            >&times;</span
          >
        </div>
        <div class="text-field-position">
          <input
            id="myInput"
            type="password"
            class="login-input"
            placeholder="Password"
            v-model="currentPassword"
            show-password
          />
          <span v-if="clearPassword" class="removeClick" @click="togglePassword"
            ><i class="el-icon-view"></i
          ></span>
        </div>

        <el-button type="text" @click="open" blur>Forgot Password</el-button>

        <div>
          <el-button type="primary" @click="submitForm" round
            >Sign In</el-button
          >
        </div>
      </div>

      <br />
      <div id="outer" v-if="mfaRequired == false">
        <div class="inner">
          <el-button type="info" round>Cloud First<br />PACS</el-button>
        </div>
        <div class="inner">
          <el-button type="info" round>AI Enabled<br />Workflow</el-button>
        </div>
        <div class="inner">
          <el-button type="info" round>Advanced<br />Visualization</el-button>
        </div>
        <br/>
        <div class="poweredByAikenist" v-if="loginClient == 'mediqas'">
          <span>Powered by</span> <img style="width: 200px;" src="@/assets/AIkenist.png" />
        </div>
      </div>
      <el-tooltip
        class="item"
        effect="dark"
        content="Change Language"
        placement="top-start"
      >
        <img
          class="languageButton"
          src="@/assets/language.png"
          height="20px"
          @click="openLanguageDialog()"
        />
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { postDataPost, validateMFA } from "@/services/login/postLogin";
import { Mixins } from "@/mixins/mixins.code.js";
import radConfig from "@/config/config";
import GoogleTranslate from "./../GoogleTranslate.vue";

export default {
  components: {
    GoogleTranslate,
  },
  data() {
    return {
      isMediqas: false,
      isGdcrib: false,
      isLocalHost: false,
      loginDetails: "",
      styleChanged: false,
      showLanguageDialog: false,
      loginClient: "",
      msg: [],
      cleartext: false,
      clearPassword: false,
      currentEmail: "",
      currentPassword: "",
      mfaRequired: false,

      posts: {
        password: "",
        email: "",
      },
      mfaDetails: {
        mfa_code: "",
        user_type: "",
        user_email: "",
      },
      carouselItems: [
        {
          image: require("@/assets/bg_slider1.png"),
        },
        {
          image: require("@/assets/bg_slider2.png"),
        },
        {
          image: require("@/assets/bg_slider1.png"),
        },
        {
          image: require("@/assets/bg_slider2.png"),
        },
      ],
    };
  },

  watch: {
    currentEmail(value) {
      if (value.length > 0) {
        this.cleartext = true;
      } else {
        this.cleartext = false;
      }
    },
  
     currentPassword(value) {
    if (value.length > 0) {
      this.clearPassword = true;
      
      this.currentPassword = value.replace(/\s+/g, "");
    } else {
      this.clearPassword = false;
    }
  },
  },

  mounted() {

    // if (window.location.href.includes("local"))
    if (window.location.href.includes("mediqasmed"))
    {
      document.title = "Mediqas";
      this.isMediqas = true;
    }

// ===================================================================== //

    if (window.location.href.includes("gdcrib")) {
      this.isGdcrib = true;
    } else if (window.location.href.includes("localhost")) {
      this.isLocalHost = true;
    }
  },

  beforeDestroy() {
    clearInterval(this.sliderInterval);
  },
  methods: {
    checkQuotaExceededError(e) {
      if (
        e.name === "QUOTA_EXCEEDED_ERR" ||
        e.name === "NS_ERROR_DOM_QUOTA_REACHED" ||
        e.name === "QuotaExceededError" ||
        e.code === 22 ||
        e.code === 1014
      ) {
        this.doHardReload();
      }
    },
    redirectToAikenSuperAdmin() {
      this.$router.push({ name: "AikenSuperAdminLogin" });
    },
    validateMfaLogin() {
      this.mfaDetails.user_type = this.loginDetails.data.user_type;
      this.mfaDetails.user_email = this.currentEmail.trim().toLowerCase();

      validateMFA(this.mfaDetails).then((res) => {
        console.log(res);
        let loginStatus = res.data.status;
        let loginAccessToken = res.data.token;
        sessionStorage.setItem(
          "modify_report",
          res.data.is_allowed_to_modify_report
        );
        sessionStorage.setItem(
          "create_report",
          res.data.is_create_report_for_allotted_radio
        );

        if (
          loginStatus == true &&
          this.loginDetails.data.user_type == "admin"
        ) {
          localStorage.setItem(
            "is_restrict_centres",
            this.loginDetails.data.is_restrict_centres
          );
          try {
            localStorage.setItem(
              "misAccess",
              this.loginDetails.data.mis.is_mis_access_allowed
            );
            localStorage.setItem(
              "InvoiceAccess",
              this.loginDetails.data.invoice.is_invoice_allowed
            );
          } catch (e) {
            this.checkQuotaExceededError(e);
          }

          if (this.loginDetails.data.is_diag == false) {
            this.OpenLoader();
            try {
              localStorage.setItem("token", loginAccessToken);
              localStorage.setItem("loginAs", "telerad");
            } catch (e) {
              this.checkQuotaExceededError(e);
            }

            this.$router.push({
              name: "AdminMaster",
            });
            this.CloseLoader();
          } else if (this.loginDetails.data.is_diag == true) {
            this.OpenLoader();
            try {
              localStorage.setItem(
                "edit_patient_info",
                this.loginDetails.data.is_edit_patient_info_allowed
              );
              localStorage.setItem(
                "scan_download",
                this.loginDetails.data.is_download_of_scans_allowed
              );
              localStorage.setItem("token", loginAccessToken);
              localStorage.setItem("loginAs", "diag");
            } catch (e) {
              this.checkQuotaExceededError(e);
            }
            this.$router.push({
              name: "DiagMaster",
            });
            this.CloseLoader();
          }
        } else if (
          loginStatus == true &&
          this.loginDetails.data.user_type == "operator"
        ) {
          this.OpenLoader();
          try {
            localStorage.setItem("token", loginAccessToken);
            localStorage.setItem("loginAs", "operator");
          } catch (e) {
            this.checkQuotaExceededError(e);
          }
          this.$router.push({
            name: "Operator",
          });
          this.CloseLoader();
        } else if (
          loginStatus == true &&
          this.loginDetails.data.user_type == "radiologist"
        ) {
          this.OpenLoader();
          try {
            localStorage.setItem("token", loginAccessToken);
            localStorage.setItem(
              "peerReviewAllowed",
              this.loginDetails.data.is_peer_review_allowed
            );
            localStorage.setItem("loginAs", "rad");
          } catch (e) {
            this.checkQuotaExceededError(e);
          }
          this.$router.push({
            name: "RadMaster",
          });
          this.CloseLoader();
        } else if (
          loginStatus == true &&
          this.loginDetails.data.user_type == "physician"
        ) {
          this.OpenLoader();
          try {
            localStorage.setItem("token", loginAccessToken);
            localStorage.setItem("loginAs", "phys");
          } catch (e) {
            this.checkQuotaExceededError(e);
          }
          this.$router.push({
            name: "PhysMaster",
          });
          this.CloseLoader();
        } else if (
          loginStatus == true &&
          this.loginDetails.data.user_type == "referrer" &&
          this.loginDetails.data.is_signed_up == false
        ) {
          this.OpenLoader();
          try {
            localStorage.setItem("token", loginAccessToken);
            localStorage.setItem("loginAs", "ref");
            localStorage.setItem("isSignedIn", "false");
          } catch (e) {
            this.checkQuotaExceededError(e);
          }
          this.$router.push({
            name: "UpdateProfile",
          });
          this.CloseLoader();
        } else if (
          loginStatus == true &&
          this.loginDetails.data.user_type == "referrer" &&
          this.loginDetails.data.is_signed_up == true
        ) {
          this.OpenLoader();
          // console.log("ac 2 logged in");
          try {
            localStorage.setItem("token", loginAccessToken);
            localStorage.setItem("loginAs", "ref");
            localStorage.setItem("isSignedIn", "true");
          } catch (e) {
            this.checkQuotaExceededError(e);
          }
          this.$router.push({
            name: "refMaster",
          });
          this.CloseLoader();
        } else {
          this.$notify.error({
            title: "Please enter valid credentials.",
            message: "",
            duration: "2500",
          });
        }
      });
    },
    observeStyleChange() {
      const targetNode = document.body;
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "style"
          ) {
            const topValue = targetNode.style.top;
            try {
              if (topValue === "0px") {
                this.styleChanged = false;
                console.log("UI unchanged");
                localStorage.setItem("tooltipTransform", "false");
              } else {
                this.styleChanged = true;
                console.log("UI Shifted");
                localStorage.setItem("tooltipTransform", "true");
              }
            } catch (e) {
              this.checkQuotaExceededError(e);
            }
          }
        });
      });

      observer.observe(targetNode, {
        attributes: true,
        attributeFilter: ["style"],
      });
    },
    openLanguageDialog() {
      this.showLanguageDialog = !this.showLanguageDialog;
      console.log(process.env.NODE_ENV); // Should print 'development' or 'production'
    },

    doHardReload() {
      localStorage.clear();
      sessionStorage.clear();
      caches.keys().then(function (names) {
        for (let name of names) {
          caches.delete(name);
        }
      });
      location.reload();
    },
    routeToUserPage() {
      this.$router.push({ name: "User_login" });
    },
    togglePassword() {
      var x = document.getElementById("myInput");
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },

    clearemail() {
      // console.log(this.currentEmail);
      this.currentEmail = "";
    },
    validateEmail(value) {
      if (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        )
      ) {
        this.msg["email"] = "";
      } else {
        this.msg["email"] = "Invalid Email Address";
      }
    },

    submitForm() {
      try {
        this.posts.email = this.currentEmail.trim().toLowerCase();
        this.posts.password = this.currentPassword;
        localStorage.setItem("currentEmail", this.currentEmail);
        postDataPost(this.posts).then((res) => {
          this.loginDetails = res;
          // console.log(res.data);
          let loginStatus = res.data.status;
          let loginAccessToken = res.data.token;
          let allottement_allowed = res.data.is_allotment_allowed;
          let share_allowed = res.data.is_share_allowed;
          let pacs_type = res.data.pacs_type[0];
          let previousReportDeletionAllowed = res.data.is_previous_patient_report_deletion_allowed;
          let adjustTatAllowed = res.data.is_allowed_to_bring_tat_within_cutoff;
          let misOnlyAllowed = res.data.is_show_only_mis;
          localStorage.setItem("allotment", allottement_allowed);
          localStorage.setItem("share_allowed", share_allowed);
          localStorage.setItem("pacs_type", pacs_type);
          localStorage.setItem("previousReportDeletion", previousReportDeletionAllowed);
          localStorage.setItem("adjustTatAllowed", adjustTatAllowed);
          localStorage.setItem("misOnlyAllowed", misOnlyAllowed);

          sessionStorage.setItem(
            "modify_report",
            res.data.is_allowed_to_modify_report
          );
          sessionStorage.setItem(
            "create_report",
            res.data.is_create_report_for_allotted_radio
          );

          radConfig.allottement_status = res.data.is_allotment_allowed;

          if (res.data.is_blocked == true) {
            this.$notify.error({
              title: "User is Blocked.",
              message: "Please contact your admin for further details.",
              duration: "2500",
            });
            return;
          }

          console.log(res.data);
          localStorage.setItem("UserName", res.data.name);
          if (
            res.data.telerad_security_policy.idle_session_timeout.is_required ==
            true
          ) {
            localStorage.setItem(
              "inactivityTimeout",
              res.data.telerad_security_policy.idle_session_timeout
                .timeout_minutes *
                60 *
                1000
            );
          } else {
            localStorage.setItem("inactivityTimeout", Infinity);
          }

          let name = this.currentEmail.substring(
            0,
            this.currentEmail.lastIndexOf("@")
          );
          localStorage.setItem("LoginName", name);

          // **********************  MFA Login ************************ //

          if (res.data.is_mfa_required == true) {
            this.mfaRequired = true;
            this.mfa_code = "";
            console.log("MFA Required");
          } else {
            if (loginStatus == true && res.data.user_type == "admin") {
              localStorage.setItem(
                "is_restrict_centres",
                res.data.is_restrict_centres
              );
              localStorage.setItem(
                "misAccess",
                res.data.mis.is_mis_access_allowed
              );
              localStorage.setItem(
                "InvoiceAccess",
                res.data.invoice.is_invoice_allowed
              );

              if (res.data.is_diag == false) {
                this.OpenLoader();
                localStorage.setItem("token", loginAccessToken);
                localStorage.setItem("loginAs", "telerad");

                this.$router.push({
                  name: "AdminMaster",
                });
                this.CloseLoader();
              } else if (res.data.is_diag == true) {
                this.OpenLoader();
                localStorage.setItem(
                  "edit_patient_info",
                  this.loginDetails.data.is_edit_patient_info_allowed
                );
                localStorage.setItem(
                  "scan_download",
                  this.loginDetails.data.is_download_of_scans_allowed
                );
                localStorage.setItem("token", loginAccessToken);
                localStorage.setItem("loginAs", "diag");
                this.$router.push({
                  name: "DiagMaster",
                  // name: "DiagCenter",
                });
                this.CloseLoader();
              }
            } else if (
              loginStatus == true &&
              res.data.user_type == "operator"
            ) {
              this.OpenLoader();
              localStorage.setItem("token", loginAccessToken);
              localStorage.setItem("loginAs", "operator");
              this.$router.push({
                name: "Operator",
              });
              this.CloseLoader();
            } else if (
              loginStatus == true &&
              res.data.user_type == "radiologist"
            ) {
              this.OpenLoader();
              localStorage.setItem("token", loginAccessToken);
              localStorage.setItem(
                "peerReviewAllowed",
                res.data.is_peer_review_allowed
              );
              localStorage.setItem("loginAs", "rad");
              this.$router.push({
                name: "RadMaster",
              });
              this.CloseLoader();
            } else if (
              loginStatus == true &&
              res.data.user_type == "physician"
            ) {
              this.OpenLoader();
              localStorage.setItem("token", loginAccessToken);
              localStorage.setItem("loginAs", "phys");
              this.$router.push({
                name: "PhysMaster",
              });
              this.CloseLoader();
            } else if (
              loginStatus == true &&
              res.data.user_type == "referrer" &&
              res.data.is_signed_up == false
            ) {
              this.OpenLoader();
              localStorage.setItem("token", loginAccessToken);
              localStorage.setItem("loginAs", "ref");
              localStorage.setItem("isSignedIn", "false");
              this.$router.push({
                name: "UpdateProfile",
              });
              this.CloseLoader();
            } else if (
              loginStatus == true &&
              res.data.user_type == "referrer" &&
              res.data.is_signed_up == true
            ) {
              this.OpenLoader();
              // console.log("ac 2 logged in");
              localStorage.setItem("token", loginAccessToken);
              localStorage.setItem("loginAs", "ref");
              localStorage.setItem("isSignedIn", "true");
              this.$router.push({
                name: "refMaster",
              });
              this.CloseLoader();
            } else {
              this.$notify.error({
                title: "Please enter valid credentials.",
                message: "",
                duration: "2500",
              });
            }
          }
        });
      } catch (e) {
        this.checkQuotaExceededError(e);
      }
    },

    open() {
      this.$router.push({
        name: "ForgotPassword",
      });
    },
  },

  created() {
    let currVersion = `${radConfig.version}`;
    let prevVersion = localStorage.getItem("software_version");

    if (prevVersion == null || prevVersion == undefined || prevVersion == "") {
      // console.log("No Version Number Found");
      caches.keys().then(function (names) {
        for (let name of names) {
          caches.delete(name);
        }
      });
      try {
        localStorage.setItem("software_version", currVersion);
      } catch (e) {
        this.checkQuotaExceededError(e);
      }
      location.reload();
    }

    if (currVersion == prevVersion) {
      // console.log("No Updates");
    } else {
      caches.keys().then(function (names) {
        for (let name of names) {
          caches.delete(name);
        }
      });
      try {
        localStorage.setItem("software_version", currVersion);
      } catch (e) {
        this.checkQuotaExceededError(e);
      }
      location.reload();
      // console.log("Application Version Updated.");
    }

    if (localStorage.token != null) {
      if (localStorage.loginAs == "telerad") {
        this.$router.push({
          name: "AdminMaster",
        });
      } else if (localStorage.loginAs == "diag") {
        this.$router.push({
          name: "DiagMaster",
        });
      } else if (localStorage.loginAs == "rad") {
        this.$router.push({
          name: "RadMaster",
        });
      } else if (localStorage.loginAs == "phys") {
        this.$router.push({
          name: "PhysMaster",
        });
      } else if (
        localStorage.loginAs == "ref" &&
        localStorage.isSignedIn == "true"
      ) {
        this.$router.push({
          name: "refMaster",
        });
      }
    }

    this.loginClient = `${radConfig.client}`;
  },

  mixins: [Mixins],
};
</script>

<style>
.el-carousel {
  position: relative;
  margin-left: 10px;
}

button.el-carousel__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 24px;
}

button.el-carousel__arrow.el-carousel__arrow--left {
  left: 0;
  margin-left: 10px;
}

button.el-carousel__arrow.el-carousel__arrow--right {
  right: 0;
  margin-right: 10px;
}
.carousel-container {
  flex: 1;
  max-width: 66.6%;
  background-color: white;
}

.poweredByAikenist {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    font-style: italic;
    margin-top: 50px;
    font-size: 20px;
}

.carousel-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.carousel-image {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.center-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 20px; */
  text-align: center;
}

.center-logo img {
  width: 100px;
  height: auto;
}

.login {
  position: relative;
}

.login-input {
  display: inline-block;
  height: 40px;
  width: 90%;
  font-size: 15px;
  color: #666666;
  text-indent: 8px;
  border: 1px solid #2d2d2d;
  margin: 15px !important;
  border: 0px solid #2d2d2d;
  border-bottom: 1px solid #3d3131;
}

.text-field-position {
  position: relative;
}

.text-field-position span {
  position: absolute;
  right: 2em;
  top: 0.5em;
  bottom: 0;
  line-height: 40px;
}

.languageButton:hover {
  cursor: pointer;
  scale: 1.05;
}
.languageButton {
  position: fixed;
  bottom: 10px;
  right: 5px;
  z-index: 1;
  cursor: pointer;
}

.goog-te-gadget {
  font-size: 10px !important;
}

.bg {
  background-image: url("../assets/bg.jpeg");
  height: 100vh;
  width: 70vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.medi_bg {
  width: 70vw;
  height: auto;
  margin: auto 0;
}

.login-div {
  position: absolute;
  padding: 10px;
  top: 0%;
  right: 0%;
  font-size: 25px;
  background-color: #fafafa;
  font-family: "Roboto";
  height: 100vh;
  width: 33vw;
  display: inline-block;
}

.UserLoginLink {
  position: fixed;
  margin: 20px;
  cursor: pointer;
  text-decoration: underline;
  z-index: 99;
}

.HardReloadButton {
  position: fixed;
  cursor: pointer;
  text-decoration: underline;
  z-index: 99;
  bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
  color: darkcyan;
}
.HardReloadButton2 {
  position: fixed;
  cursor: pointer;
  text-decoration: underline;
  z-index: 99;
  bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 100px;
  color: darkcyan;
}

.logos {
  display: flex;
  flex-direction: row;
  height: 20%;
  align-items: center;
  justify-content: space-between;
}

i.el-icon-view {
  font-size: 14px !important;
  /* margin: 0px !important; */
  padding: 0px !important;
  color: black !important;
}

#img-01 {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  width: 40%;
  height: 50%;
}

#img-01::before {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  width: 40%;
  height: 50%;
}

#img-03 {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  height: 45%;
}

#img-03::before {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  height: 45%;
}

#img-02 {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  width: 10%;
  height: auto;
  justify-content: flex-end;
}

#img-02::before {
  margin-top: 30px;
  display: flex;
  flex-direction: row-reverse;
  width: 10%;
  height: auto;
  justify-content: flex-end;
}

.rad {
  color: black !important;
  font-family: "Roboto";
  font-weight: 700;
  margin-top: 12%;
  margin-bottom: 10%;
  padding: 1%;
}

.el-icon-user-solid {
  color: black !important;
}

.el-icon-user-solid:before {
  color: black !important;
}

.inner {
  margin: 1% !important;
  display: inline-block;
  justify-content: center;
}

/* .el-notification {
  background-color: rgba(218, 122, 122, 0.9) !important;
  color: black !important;
} */

.inner_login-div {
  margin-top: 30px;
  margin-bottom: 0px;
  width: 80%;
  margin-left: 10% !important;
  padding: 2px;
}

.submitMfaCode {
  color: #fff !important;
  background-color: #409eff !important;
  /* border-color: #409eff !important; */
  border: none !important;
  font-size: 16px !important;
  border-radius: 20px !important;
  padding: 5px 10px !important;
  transition: 1.1s !important;
  cursor: pointer;
}

.el-button.el-button--primary {
  color: #fff !important;
  background-color: #409eff !important;
  border-color: #409eff !important;
  margin: 3px !important;
  margin-bottom: 8% !important;
  transition: 1.1s !important;
  font-weight: 900 !important;
  margin-left: 40% !important;
  font-size: 16px !important;
  border-radius: 20px !important;
  padding: 12px 23px !important;
}

.el-button.el-button--info {
  color: #fff !important;
  background-color: #909399 !important;
  margin: 5px !important;
  transition: 1.1s !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  margin-left: 27% !important;
  margin-top: 20% !important;
  padding: 5px !important;
  height: 4.5vh !important;
  width: 8vw !important;
  text-align: center !important;
  border-radius: 30px !important;
}

.el-button--text {
  color: #0080ff !important;
  margin-top: 1% !important;
}

@media only screen and (max-width: 1500px) {
  .el-button.el-button--info {
    margin: 5px !important;
    transition: 1.1s !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    margin-left: 20% !important;
    padding: 8px !important;
    height: 5vh !important;
    width: 9vw !important;
    text-align: center !important;
    border-radius: 30px !important;
  }

  .logos {
    display: flex;
    flex-direction: row;
    height: 20%;
    align-items: center;
    justify-content: space-between;
  }

  #img-01::before {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 40%;
    height: 45%;
  }

  #img-03::before {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    height: 40%;
  }

  #img-02::before {
    margin-top: 30px;
    display: flex;
    flex-direction: row-reverse;
    width: 10%;
    height: 20%;
    justify-content: flex-end;
  }

  #img-01 {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 40%;
    height: 45%;
  }

  #img-03 {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    height: 40%;
  }

  #img-02 {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 10%;
    height: 20%;
    justify-content: flex-end;
  }
}

@media only screen and (max-height: 800px) and (min-width: 1300px) {
  .login-div {
    position: absolute;
    padding: 10px;
    top: 0%;
    right: 0%;
    font-size: 25px;
    background-color: #fafafa;
    font-family: "Roboto";
    height: 100%;
    width: 33vw;
    display: inline-block;
  }

  #img-01::before {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
  }

  #img-01 {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
  }

  #img-03::before {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
  }

  #img-03 {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
  }

  #img-02::before {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 10%;
    height: 20%;
    justify-content: flex-end;
  }

  #img-02 {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 10%;
    height: 20%;
    justify-content: flex-end;
  }

  .rad {
    font-family: "Roboto";
    font-weight: 700;
    margin-top: 12%;
    margin-bottom: 10%;
    padding: 1%;
  }

  .el-radio {
    margin-left: 30;
    display: inline !important;
    cursor: pointer !important;
    margin: 5% !important;
    margin-left: 10% !important;
    font-family: "Roboto" !important;
    font-weight: 900 !important;
    font-size: larger !important;
    padding: 5% !important;
    margin-right: 30px !important;
    justify-content: center !important;
  }

  .login-input {
    display: inline-block;
    height: 30px;
    width: 80%;
    font-size: 15px;
    color: #666666;
    margin: 15px !important;
    text-indent: 8px;
    border: 0px solid #2d2d2d;
    border-bottom: 1px solid #3d3131;
  }

  .inner_login-div {
    margin-top: 30px;
    margin-bottom: 0px;
    width: 80%;
    margin-left: 10% !important;
    padding: 2px;
  }

  .el-button.el-button--primary {
    color: #fff !important;
    background-color: #409eff !important;
    border-color: #409eff !important;
    margin: 3px !important;
    margin-bottom: 3% !important;
    font-weight: 600 !important;
    margin-left: 40% !important;
    font-size: 16px !important;
    border-radius: 20px !important;
    padding: 12px 23px !important;
  }

  .el-button.el-button--info {
    margin: 5px !important;
    transition: 1.1s !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    margin-left: 20% !important;
    padding: 8px !important;
    height: 5.5vh !important;
    width: 9vw !important;
    text-align: center !important;
    border-radius: 30px !important;
    justify-content: center;
  }
}

@media only screen and (max-height: 700px) and (min-width: 1300px) {
  .login-div {
    position: absolute;
    padding: 10px;
    top: 0%;
    right: 0%;
    font-size: 25px;
    background-color: #fafafa;
    font-family: "Roboto";
    height: 100%;
    width: 33vw;
    display: inline-block;
  }

  .rad {
    font-family: "Roboto";
    font-weight: 700;
    margin-top: 10%;
    margin-bottom: 8%;
    padding: 1%;
  }

  .el-radio {
    margin-left: 30;
    display: inline !important;
    cursor: pointer !important;
    margin: 4% !important;
    margin-left: 10% !important;
    font-family: "Roboto" !important;
    font-weight: 900 !important;
    font-size: larger !important;
    padding: 5% !important;
    margin-right: 30px !important;
    justify-content: center !important;
  }

  .inner_login-div {
    margin-top: 30px;
    margin-bottom: 0px;
    width: 80%;
    margin-left: 10% !important;
    padding: 2px;
  }

  /* 
  .el-input__inner {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    border-radius: 0px !important;
    background-color: #fafafa !important;
    border: 1px solid #3a3a3b !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    color: #080808 !important;
    font-size: large !important;
  } */

  .el-button.el-button--primary {
    color: #fff !important;
    background-color: #409eff !important;
    border-color: #409eff !important;
    margin: 3px !important;
    margin-bottom: 3% !important;
    font-weight: 600 !important;
    margin-left: 40% !important;
    font-size: 16px !important;
    border-radius: 20px !important;
    padding: 12px 23px !important;
  }

  .el-button.el-button--info {
    margin: 5px !important;
    transition: 1.1s !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    margin-left: 20% !important;
    padding: 2px !important;
    height: 5vh !important;
    width: 9vw !important;
    text-align: center !important;
    border-radius: 30px !important;
  }
}

@media only screen and (max-height: 800px) {
  .logos {
    display: flex;
    flex-direction: row;
    height: 20%;
    align-items: center;
    justify-content: space-between;
  }

  #img-01::before {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    height: 50%;
  }

  #img-03::before {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    height: 50%;
  }

  #img-02::before {
    margin-top: 30px;
    display: flex;
    flex-direction: row-reverse;
    height: 20%;
  }

  #img-01 {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    height: 50%;
  }

  #img-03 {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    height: 50%;
  }

  #img-02 {
    margin-top: 30px;
    display: flex;
    flex-direction: row-reverse;
    height: 20%;
  }
}

@media only screen and (max-width: 1300px) {
  .el-button.el-button--info {
    margin: 5px !important;
    transition: 1.1s !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    margin-left: 20% !important;
    padding: 8px !important;
    height: 5vh !important;
    width: 9vw !important;
    text-align: center !important;
    border-radius: 30px !important;
  }

  .logos {
    display: flex;
    flex-direction: row;
    height: 20%;
    align-items: center;
    justify-content: space-between;
  }

  .text-field-position span {
    position: absolute;
    right: 1.5em;
    top: 0.5em;
    bottom: 0;
    line-height: 40px;
  }

  #img-01::before {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 40%;
    height: 40%;
  }

  #img-03::before {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 40%;
    height: 40%;
  }

  #img-02::before {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 10%;
    height: 20%;
    justify-content: flex-end;
  }

  #img-01 {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 40%;
    height: 40%;
  }

  #img-03 {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 40%;
    height: 40%;
  }

  #img-02 {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    width: 10%;
    height: 20%;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 1050px) {
  .bg {
    background-image: url("../assets/bg.jpeg");
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .medi_bg {
    background-image: url("../assets/medi_bg.jpg");
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .login-div {
    position: absolute;
    padding: 4px;
    margin-top: 35%;
    margin-right: 3vw;
    right: 5%;
    font-size: 20px;
    background-color: rgba(250, 250, 250, 0.9);
    font-family: "Roboto";
    height: inherit;
    width: 85vw;
    display: inline-block;
    border-radius: 8px;
  }

  .text-field-position span {
    position: absolute;
    right: 4em;
    top: 0.5em;
    bottom: 0;
    line-height: 40px;
  }

  .logos {
    display: flex;
    flex-direction: row;
    height: 15vh;
    align-items: center;
    justify-content: flex-start;
  }

  #img-01::before {
    display: flex;
    flex-direction: row;
    height: 60%;
  }

  #img-02::before {
    display: flex;
    flex-direction: row-reverse;
    height: 40%;
  }

  #img-01 {
    display: flex;
    flex-direction: row;
    height: 60%;
  }

  #img-02 {
    display: flex;
    flex-direction: row-reverse;
    height: 40%;
  }

  .rad {
    font-weight: 600;
    font-size: 17px;
    margin-top: 2px;
    margin-bottom: 5px;
    padding: 1%;
  }

  .el-radio {
    display: inline !important;
    cursor: pointer !important;
    margin: 7% !important;
    font-family: "Roboto" !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    padding: 3% !important;
    margin-right: 15px !important;
    justify-content: center !important;
  }

  /* 
  .el-input__icon {
    width: 44px !important;
    text-align: justify !important;
    transition: all 0.3s !important;
    line-height: 65px !important;
    margin-right: 2px !important;
  } */

  .el-button.el-button--primary {
    margin: 0px !important;
    margin-bottom: 0px !important;
    transition: 1.1s !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    margin-left: 37% !important;
    padding: 11px !important;
  }

  .el-button.el-button--info {
    margin-top: 0px !important;
    transition: 1.1s !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    margin-left: 10% !important;
    padding: 5px !important;
    height: 6vh !important;
    width: 23vw !important;
    text-align: center !important;
    border-radius: 30px !important;
  }

  .el-button--text {
    color: #0080ff !important;
    margin-top: 2% !important;
  }
}

@media only screen and (max-width: 900px) {
  .bg {
    background-image: url("../assets/bg.jpeg");
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .medi_bg {
    background-image: url("../assets/medi_bg.jpg");
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .login-div {
    position: absolute;
    padding: 4px;
    margin-top: 25%;
    margin-right: 3vw;
    right: 5%;
    font-size: 20px;
    background-color: rgba(250, 250, 250, 0.9);
    font-family: "Roboto";
    height: inherit;
    width: 85vw;
    display: inline-block;
    border-radius: 8px;
  }

  .logos {
    display: flex;
    flex-direction: row;
    height: 15vh;
    align-items: center;
    justify-content: flex-start;
  }

  #img-01::before {
    display: flex;
    flex-direction: row;
    height: 60%;
  }

  #img-02::before {
    display: flex;
    flex-direction: row-reverse;
    height: 40%;
  }

  #img-01 {
    display: flex;
    flex-direction: row;
    height: 60%;
  }

  #img-02 {
    display: flex;
    flex-direction: row-reverse;
    height: 40%;
  }

  .rad {
    font-weight: 600;
    font-size: 17px;
    margin-top: 2px;
    margin-bottom: 5px;
    padding: 1%;
  }

  .el-radio {
    display: inline !important;
    cursor: pointer !important;
    margin: 7% !important;
    font-family: "Roboto" !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    padding: 3% !important;
    margin-right: 15px !important;
    justify-content: center !important;
  }

  /* .el-input__inner {
    margin-left: 10% !important;
    margin-top: 4% !important;
    margin-bottom: 1px !important;
    border-radius: 0px !important;
    background-color: rgba(250, 250, 250, 0.5) !important;
    border: 1px solid #3a3a3b !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    color: #080808 !important;
    font-size: 13px !important;
    width: 80% !important;
  }

  .el-input__icon {
    width: 44px !important;
    text-align: justify !important;
    transition: all 0.3s !important;
    line-height: 65px !important;
    margin-right: 2px !important;
  } */

  .el-button.el-button--primary {
    margin: 0px !important;
    margin-bottom: 0px !important;
    transition: 1.1s !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    margin-left: 37% !important;
    padding: 11px !important;
  }

  .el-button.el-button--info {
    margin-top: 0px !important;
    transition: 1.1s !important;
    font-weight: 100 !important;
    font-size: 18px !important;
    margin-left: 10% !important;
    padding: 5px !important;
    height: 6vh !important;
    width: 23vw !important;
    text-align: center !important;
    border-radius: 30px !important;
  }

  .el-button--text {
    color: #0080ff !important;
    margin-top: 2% !important;
  }
}

@media only screen and (max-width: 700px) {
  .bg {
    background-image: url("../assets/bg.jpeg");
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .medi_bg {
    background-image: url("../assets/medi_bg.jpg");
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .login-div {
    position: absolute;
    padding: 4px;
    margin-top: 35%;
    margin-right: 3vw;
    right: 5%;
    font-size: 20px;
    background-color: rgba(250, 250, 250, 0.9);
    font-family: "Roboto";
    height: inherit;
    width: 85vw;
    display: inline-block;
    border-radius: 8px;
  }

  .text-field-position span {
    position: absolute;
    right: 5.5em;
    top: 0.5em;
    bottom: 0;
    line-height: 40px;
  }

  .login-input {
    display: inline-block;
    height: 30px;
    width: 80%;
    font-size: 15px;
    color: #666666;
    margin: 15px !important;
    text-indent: 8px;
    border: 0px solid #2d2d2d;
    border-bottom: 1px solid #3d3131;
  }

  .logos {
    display: flex;
    flex-direction: row;
    height: 15vh;
    align-items: center;
    justify-content: flex-start;
  }

  #img-01::before {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    height: 40%;
  }

  #img-02::before {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
    height: 30%;
  }

  #img-01 {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    height: 40%;
  }

  #img-02 {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
    height: 30%;
  }

  .rad {
    font-weight: 600;
    font-size: 17px;
    margin-top: 2px;
    margin-bottom: 5px;
    padding: 1%;
  }

  .el-radio {
    display: inline !important;
    cursor: pointer !important;
    margin: 7% !important;
    font-family: "Roboto" !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    padding: 3% !important;
    margin-right: 15px !important;
    justify-content: center !important;
  }

  /* .el-input__inner {
    margin-left: 10% !important;
    margin-top: 4% !important;
    margin-bottom: 1px !important;
    border-radius: 0px !important;
    background-color: rgba(250, 250, 250, 0.5) !important;
    border: 1px solid #3a3a3b !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    color: #080808 !important;
    font-size: 13px !important;
    width: 80% !important;
  }

  .el-input__icon {
    width: 44px !important;
    text-align: justify !important;
    transition: all 0.3s !important;
    line-height: 65px !important;
    margin-right: 2px !important;
  } */

  .el-button.el-button--primary {
    margin: 0px !important;
    margin-bottom: 0px !important;
    transition: 1.1s !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    margin-left: 37% !important;
    padding: 11px !important;
  }

  .el-button.el-button--info {
    margin-top: 0px !important;
    transition: 1.1s !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    margin-left: 10% !important;
    padding: 5px !important;
    height: 5vh !important;
    width: 23vw !important;
    text-align: center !important;
    border-radius: 30px !important;
  }

  .el-button--text {
    color: #0080ff !important;
    margin-top: 2% !important;
  }
}

@media only screen and (max-width: 600px) {
  .text-field-position span {
    position: absolute;
    right: 5em;
    top: 0.5em;
    bottom: 0;
    line-height: 40px;
  }
}

@media only screen and (max-width: 500px) {
  .text-field-position span {
    position: absolute;
    right: 4em;
    top: 0.5em;
    bottom: 0;
    line-height: 40px;
  }
}

@media only screen and (max-width: 400px) {
  .bg {
    background-image: url("../assets/bg.jpeg");
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .medi_bg {
    background-image: url("../assets/medi_bg.jpg");
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .text-field-position span {
    position: absolute;
    right: 3em;
    top: 0.5em;
    bottom: 0;
    line-height: 40px;
  }

  .login-div {
    position: absolute;
    padding: 4px;
    margin-top: 35%;
    margin-right: 3vw;
    right: 5%;
    font-size: 20px;
    background-color: rgba(250, 250, 250, 0.9);
    font-family: "Roboto";
    height: inherit;
    width: 85vw;
    display: inline-block;
    border-radius: 8px;
  }

  .login-input {
    display: inline-block;
    height: 30px;
    width: 80%;
    font-size: 15px;
    color: #666666;
    margin: 15px !important;
    text-indent: 8px;
    border: 0px solid #2d2d2d;
    border-bottom: 1px solid #3d3131;
  }

  .logos {
    display: flex;
    flex-direction: row;
    height: 15vh;
    align-items: center;
    justify-content: flex-start;
  }

  #img-01::before {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    height: 40%;
  }

  #img-02::before {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
    height: 25%;
  }

  #img-01 {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    height: 40%;
  }

  #img-02 {
    margin-top: 20px;
    display: flex;
    flex-direction: row-reverse;
    height: 25%;
  }

  .rad {
    font-weight: 600;
    font-size: 17px;
    margin-top: 2px;
    margin-bottom: 5px;
    padding: 1%;
  }

  .el-radio {
    display: inline !important;
    cursor: pointer !important;
    margin: 7% !important;
    font-family: "Roboto" !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    padding: 3% !important;
    margin-right: 15px !important;
    justify-content: center !important;
  }

  /* .el-input__inner {
    margin-left: 5% !important;
    margin-top: 4% !important;
    margin-bottom: 1px !important;
    border-radius: 0px !important;
    background-color: rgba(250, 250, 250, 0.5) !important;
    border: 1px solid #3a3a3b !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    color: #080808 !important;
    font-size: 11px !important;
    width: 90% !important;
  } */

  .el-button.el-button--primary {
    margin: 0px !important;
    margin-bottom: 0px !important;
    transition: 1.1s !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    margin-left: 37% !important;
    padding: 11px !important;
  }

  .el-button.el-button--info {
    margin-top: 0px !important;
    transition: 1.1s !important;
    font-weight: 550 !important;
    font-size: 11px !important;
    margin-left: 8% !important;
    padding: 5px !important;
    height: 6vh !important;
    width: 24vw !important;
    text-align: center !important;
    border-radius: 30px !important;
  }

  .el-button--text {
    color: #0080ff !important;
    margin-top: 2% !important;
  }
}

@media only screen and (max-width: 300px) {
  .bg {
    background-image: url("../assets/bg.jpeg");
    height: 100vh;
    width: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .medi_bg {
    background-image: url("../assets/medi_bg.jpg");
    height: 100vh;
    width: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .text-field-position span {
    position: absolute;
    right: 2em;
    top: 0.5em;
    bottom: 0;
    line-height: 40px;
  }

  .login-div {
    position: absolute;
    padding: 4px;
    margin-top: 35%;
    margin-right: 3vw;
    right: 5%;
    font-size: 20px;
    background-color: rgba(250, 250, 250, 0.9);
    font-family: "Roboto";
    height: inherit;
    width: 85vw;
    display: inline-block;
    border-radius: 8px;
  }

  .logos {
    display: flex;
    flex-direction: row;
    height: 15vh;
    align-items: center;
    justify-content: flex-start;
  }

  .login-input {
    display: inline-block;
    height: 30px;
    width: 80%;
    font-size: 15px;
    color: #666666;
    margin: 15px !important;
    text-indent: 8px;
    border: 0px solid #2d2d2d;
    border-bottom: 1px solid #3d3131;
  }

  #img-01::before {
    display: flex;
    flex-direction: row;
    height: 40%;
  }

  #img-02::before {
    display: flex;
    flex-direction: row-reverse;
    height: 20%;
  }

  #img-01 {
    display: flex;
    flex-direction: row;
    height: 40%;
  }

  #img-02 {
    display: flex;
    flex-direction: row-reverse;
    height: 20%;
  }

  .rad {
    font-weight: 600;
    font-size: 17px;
    margin-top: 2px;
    margin-bottom: 5px;
    padding: 1%;
  }

  .el-radio {
    display: inline !important;
    cursor: pointer !important;
    margin: 4% !important;
    font-family: "Roboto" !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    padding: 3% !important;
    margin-right: 15px !important;
    justify-content: center !important;
  }

  /* .el-input__inner {
    margin-left: 5% !important;
    margin-top: 4% !important;
    margin-bottom: 1px !important;
    border-radius: 0px !important;
    background-color: rgba(250, 250, 250, 0.5) !important;
    border: 1px solid #3a3a3b !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    color: #080808 !important;
    font-size: 10px !important;
    width: 90% !important;
  } */

  /* .el-input__icon {
    width: 44px !important;
    text-align: justify !important;
    transition: all 0.3s !important;
    line-height: 65px !important;
    margin-right: 2px !important;
  } */

  .el-button.el-button--primary {
    margin: 0px !important;
    margin-bottom: 0px !important;
    transition: 1.1s !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    margin-left: 37% !important;
    padding: 11px !important;
  }

  .el-button.el-button--info {
    margin-top: 0px !important;
    transition: 1.1s !important;
    font-weight: 400 !important;
    font-size: 10px !important;
    margin-left: 5% !important;
    padding: 5px !important;
    height: 5vh !important;
    width: 23.5vw !important;
    text-align: center !important;
    border-radius: 30px !important;
  }

  .el-button--text {
    color: #0080ff !important;
    margin-top: 2% !important;
  }
}
</style>
